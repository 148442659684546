// @ts-check
import {yupResolver} from "@hookform/resolvers/yup";
import {Button, Divider, Typography} from "@mui/material";
import _ from "lodash";
import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import * as yup from "yup";

import {selectCurrentUser} from "../../../redux/app_selectors";
import {PERMISSION, useSecurity} from "../../../utils/security";
import {AdornmentModified} from "../../shared/adornment_modified/adornment_modified";
import {ControlledTextField} from "../../shared/controlled_form";
import PasswordRules from "../../shared/password_rules/password_rules";
import PermissionTooltip from "../../shared/permission_tooltip";
import useStyles from "../account.styles";
import {updatePasswordAction} from "../account_actions";

const userPasswordSchema = yup.object().shape({
    passwordOld: yup.string().min(6, "Account.old_password_wrong").required("Account.field_required"),
    passwordNew: yup
        .string()
        .min(12, "AdminUserLayer.passwordError")
        .required("Account.field_required")
        .notOneOf([yup.ref("passwordOld")], "AdminUserLayer.passwordError")
        .matches(/^(?=.*\W).*$/i, "AdminUserLayer.passwordError")
        .matches(/^(?!.*([A-Za-z0-9])\1{2}).*$/, "AdminUserLayer.passwordError")
        .test("notInvalidWords", "AdminUserLayer.passwordError", (value) => !value.includes("nextor")),
    passwordNewConfirm: yup
        .string()
        .oneOf([yup.ref("passwordNew")], "Account.passwords_unequal")
        .required("AdminUserLayer.passwordError")
});

const defaultValues = {
    passwordOld: "",
    passwordNew: "",
    passwordNewConfirm: ""
};

const renderinputProps = (className, dataTestId) => ({className, "data-testid": dataTestId});

export const ProfilePassword = () => {
    const {classes, cx} = useStyles();
    const {t} = useTranslation();
    const {isGranted} = useSecurity();

    const isModifyAllowed = isGranted(PERMISSION.MODIFY_USER_OWN);
    const dispatch = useDispatch();

    // redux
    const {id} = useSelector(selectCurrentUser);

    // state
    const [showPassword, setShowPassword] = useState({passwordOld: false, passwordNew: false, passwordNewConfirm: false});
    const {passwordNew, passwordNewConfirm, passwordOld} = showPassword;

    // form
    const {control, formState, handleSubmit, reset, setError} = useForm({
        resolver: yupResolver(userPasswordSchema),
        defaultValues
    });
    const {errors, isSubmitting} = formState;
    const hasError = !_.isEmpty(errors);

    // handlers
    const handleShowPassword = (_, key) =>
        setShowPassword({
            ...showPassword,
            [key]: !showPassword[key]
        });

    const endAdornment = (textToShow, isVisible) => (
        <AdornmentModified handler={(e) => handleShowPassword(e, textToShow)} hasError={hasError} isVisible={isVisible} />
    );

    const onSubmit = ({passwordNew, passwordOld}) => {
        dispatch(updatePasswordAction({id, passwordOld, passwordNew, setError, reset}));
    };

    return (
        <div className={classes.content}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <div>
                        <Typography className={classes.sectionSubtitle} variant="h6">
                            {t("Account.changePassword")}
                        </Typography>

                        {/* Old password */}
                        <div className={cx(classes.inputWrapper, classes.oldPasswordWrapper)}>
                            <ControlledTextField
                                control={control}
                                inputProps={renderinputProps(classes.inputPassword, "password-old")}
                                InputProps={{endAdornment: endAdornment("passwordOld", passwordOld)}}
                                label={t("Account.old_password")}
                                name="passwordOld"
                                placeholder="************"
                                type={passwordOld ? "text" : "password"}
                            />
                            <div className={classes.rulesWrapper}>
                                <PasswordRules />
                            </div>
                        </div>

                        {/* New password */}
                        <div className={classes.inputWrapper}>
                            <ControlledTextField
                                control={control}
                                inputProps={renderinputProps(classes.inputPassword, "password-new")}
                                InputProps={{endAdornment: endAdornment("passwordNew", passwordNew)}}
                                label={t("Account.new_password")}
                                name="passwordNew"
                                placeholder="************"
                                type={passwordNew ? "text" : "password"}
                            />
                        </div>

                        {/* New password confirm*/}
                        <div className={classes.inputWrapper}>
                            <ControlledTextField
                                control={control}
                                inputProps={renderinputProps(classes.inputPassword, "password-new-confirm")}
                                InputProps={{endAdornment: endAdornment("passwordNewConfirm", passwordNewConfirm)}}
                                label={t("Account.new_password_confirmation")}
                                name="passwordNewConfirm"
                                placeholder="************"
                                type={passwordNewConfirm ? "text" : "password"}
                            />
                        </div>
                    </div>

                    <Divider className={classes.divider} />
                    <div className={classes.buttonsWrapper}>
                        <Button
                            className={cx(classes.button, classes.buttonCancel)}
                            color="primary"
                            data-testid="profile-info-reset-button"
                            size="small"
                            variant="outlined"
                            onClick={() => reset()}
                        >
                            {t("App.cancel")}
                        </Button>
                        <PermissionTooltip isAllowed={isModifyAllowed}>
                            <Button
                                className={classes.button}
                                color="primary"
                                data-testid="profile-info-save-button"
                                disabled={isSubmitting}
                                size="small"
                                type="submit"
                                variant="contained"
                            >
                                {t("Account.save")}
                            </Button>
                        </PermissionTooltip>
                    </div>
                </div>
            </form>
        </div>
    );
};

ProfilePassword.propTypes = {};
