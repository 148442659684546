// @ts-check
import {string} from "prop-types";
import React from "react";
import {Navigate, Outlet, useLocation} from "react-router-dom";

import {isRouteAllowed, useSecurity} from "../../utils/security";

const ACCESS_DENIED = "/access-denied";

/**
 * @link https://www.robinwieruch.de/react-router-private-routes/
 */

/**
 * Protected route, will redirect to the ACCESS_DENIED route if the access is denied
 *
 * @param {object} props
 * @param {string} [props.redirectTo=ACCESS_DENIED]
 * @return {React.ReactElement}
 */
const ProtectedRoute = ({redirectTo = ACCESS_DENIED}) => {
    const {permissions} = useSecurity();
    const location = useLocation();

    // render component if we can't detect any permission
    if (!permissions) {
        return <Outlet />;
    }

    if (permissions.length === 0) {
        return <Navigate state={{from: location}} to={redirectTo} />;
    }

    // check all configured permissions, redirectTo if user is missing any permission
    if (!isRouteAllowed(location.pathname, permissions)) {
        return <Navigate state={{from: location}} to={redirectTo} />;
    }

    return <Outlet />;
};

ProtectedRoute.propTypes = {
    redirectTo: string
};

ProtectedRoute.defaultProps = {
    redirectTo: ACCESS_DENIED
};

export default ProtectedRoute;
