/**
 * @fileoverview redux container for surgeons multi selector
 */

import {uniq} from "lodash";
import PropTypes from "prop-types";
import React, {Component} from "react";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

import {selectCurrentOrganizationId} from "../../../redux/app_selectors";
import SurgeonsMultiSelect from "../components/surgeons_multiselect";
import {loadDisciplineOptionsAction} from "../disciplines_actions";
import {selectDisciplineOptions} from "../disciplines_selectors";

/**
 * SurgeonsMultiSelectContainer
 */
class SurgeonsMultiSelectContainer extends Component {
    /**
     * lifecycle method
     */
    componentDidMount() {
        const {organizationId, loadDisciplines, disciplines} = this.props;

        if (!disciplines.length) {
            loadDisciplines(organizationId);
        }
    }
    /**
     * get options for the dropdown
     * @return {Array}
     */
    getOptions() {
        const {disciplines, selectedDisciplines} = this.props;
        let surgeons = [];
        for (const item of disciplines) {
            if (selectedDisciplines && selectedDisciplines.includes(item.text)) {
                surgeons = surgeons.concat(item.practitionerIds);
            } else if (!selectedDisciplines) {
                surgeons = surgeons.concat(item.practitionerIds);
            }
        }
        return uniq(surgeons);
    }

    /**
     * render
     * @return {jsx}
     */
    render() {
        const {values, onSelect, styles, selectSurgeons, disabled} = this.props;
        const surgeons = selectSurgeons ? selectSurgeons : this.getOptions();
        return <SurgeonsMultiSelect disabled={disabled} styles={styles} surgeons={surgeons} values={values} onSelect={onSelect} />;
    }
}

const mapStateTopProps = createStructuredSelector({
    organizationId: selectCurrentOrganizationId,
    disciplines: selectDisciplineOptions
});

const mapDispatchProps = {
    loadDisciplines: loadDisciplineOptionsAction
};

SurgeonsMultiSelectContainer.propTypes = {
    organizationId: PropTypes.string.isRequired,
    disciplines: PropTypes.arrayOf(
        PropTypes.shape({
            empIds: PropTypes.arrayOf(PropTypes.string),
            name: PropTypes.string
        })
    ),
    values: PropTypes.arrayOf(PropTypes.string).isRequired,
    loadDisciplines: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    styles: PropTypes.shape({
        inputWrapper: PropTypes.string,
        input: PropTypes.string,
        closeIcon: PropTypes.string,
        menuWrapper: PropTypes.string,
        menu: PropTypes.string,
        placeholder: PropTypes.string
    }),
    selectSurgeons: PropTypes.arrayOf(PropTypes.string),
    disabled: PropTypes.bool,
    selectedDisciplines: PropTypes.arrayOf(PropTypes.string)
};

export default connect(mapStateTopProps, mapDispatchProps)(SurgeonsMultiSelectContainer);
